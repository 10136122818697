import {
    Box,
    Button,
    Card,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    OutlinedInput,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from "@mui/material";
import { useUserProvider } from "../../../../providers/useUserProvider";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MaterialIcon from "../../../../components/MaterialIcon";
import { MEDICAL_HISTORY_KEYS } from "../../../../common/utils";
import DialogUploadDocuments from "../../../../components/dialogs/DialogUploadDocuments";
import { getRequests } from "../../../../api/requests";
import { getUsers } from "../../../../api/users";
import { downloadDocument, getDocuments, unassignDocument } from "../../../../api/documents";
import { getMedicalHistories } from "../../../../api/medicalHistory";
import { getReports, registerReport } from "../../../../api/reports";
import { compileQuestionnaire, getQuestionnaire } from "../../../../api/questionnaire";
import { useModalDialog } from "../../../../providers/useModalDialog";

const RequestDetails: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();
    const { requestId } = useParams();

    useEffect(() => {
        if (!user.userId || !user.userType) {
            navigate("/error", { state: { errorStatus: 401 } });
            return;
        }

        if (!requestId) {
            navigate("/error", { state: { errorStatus: 404 } });
            return;
        }
    }, []);

    return (
        <Box padding="2rem" boxSizing="border-box">
            <Typography
                variant="h4"
                gutterBottom
                color={theme.palette.textDark.primary}
                fontWeight="bold"
            >
                Richieste<MaterialIcon icon="arrow_right" />
                Dettaglio richiesta #{requestId}
            </Typography>
            <Typography
                variant="body1"
                marginBottom="1rem"
                color={theme.palette.textDark.primary}
            >
                Visualizza i dettagli della richiesta
            </Typography>
            {requestId && user.userId && user.userType && (
                <RequestDetailsContent id_richiesta={parseInt(requestId)} />
            )}
        </Box>
    );
};

export const RequestDetailsContent: React.FC<{
    id_richiesta: number;
    closeOnSaveFunc?: () => void;
}> = ({ id_richiesta, closeOnSaveFunc }) => {
    console.log("updated request details", id_richiesta);

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { user } = useUserProvider();

    const [data, setData] = useState<any>({
        request: null,
        patient: null,
        documents: [],
        medicalHistory: null,
        examination: [],
        reports: [],
    });

    const questionnaireRef = useRef<any>(null);

    useEffect(() => {
        if (!user.userType || !user.userId) {
            return;
        }

        loadData();
    }, [id_richiesta]);

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await getRequests({ id_richiesta });
            const patientResponse = await getUsers({
                id_utenti: response.data[0].id_paziente,
            });
            const documentsResponse = await getDocuments({ id_richiesta });
            console.log("New medical history id", response.data[0].id_anamnesi);
            const medicalHistoryResponse = await getMedicalHistories({
                id_anamnesi: response.data[0].id_anamnesi,
            });
            const reportsResponse = await getReports({ id_richiesta });

            setData({
                request: response.data[0],
                patient: patientResponse.data[0],
                documents: documentsResponse.documenti,
                medicalHistory: medicalHistoryResponse.anamnesi[0],
                examination: response.data[0].esame_obiettivo
                    ? JSON.parse(response.data[0].esame_obiettivo)
                    : [],
                reports: reportsResponse.data,
            });
        } catch (error) {
            enqueueSnackbar("Errore durante il caricamento dei dati", {
                variant: "error",
            });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleSendReport = async (report: string, idoneo: boolean) => {
        if (!questionnaireRef.current) {
            return;
        }

        const answers = questionnaireRef.current.getAnswers();

        const isQuestionnaireComplete = Object.values(answers).every(
            (answer) => answer !== ""
        );

        if (idoneo && !isQuestionnaireComplete) {
            enqueueSnackbar(
                "Per inviare l'idoneità, è necessario completare il questionario.",
                { variant: "warning" }
            );
            return;
        }

        const reportAnswers = Object.keys(answers).map((key) => {
            return {
                id_domanda: parseInt(key),
                risposta: answers[key],
            };
        });

        openLoadingDialog();
        try {
            await compileQuestionnaire({
                id_richiesta: data.request.id_richiesta,
                questionario: reportAnswers,
            });
            await registerReport({
                id_richiesta: data.request.id_richiesta,
                note_aggiuntive: report,
                idoneo,
            });

            enqueueSnackbar("Report inviato con successo", { variant: "success" });

            if (closeOnSaveFunc) {
                closeOnSaveFunc();
            } else {
                loadData();
            }
        } catch (error) {
            enqueueSnackbar("Errore durante l'invio del report", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleSaveDraft = async () => {
        if (!questionnaireRef.current) {
            return;
        }

        const answers = questionnaireRef.current.getAnswers();

        const reportAnswers = Object.keys(answers).map((key) => {
            return {
                id_domanda: parseInt(key),
                risposta: answers[key],
            };
        });

        openLoadingDialog();
        try {
            await compileQuestionnaire({
                id_richiesta: data.request.id_richiesta,
                questionario: reportAnswers,
            });

            enqueueSnackbar("Bozza salvata con successo", { variant: "success" });

            if (closeOnSaveFunc) {
                closeOnSaveFunc();
            } else {
                loadData();
            }
        } catch (error) {
            enqueueSnackbar("Errore durante il salvataggio della bozza", {
                variant: "error",
            });
        } finally {
            closeLoadingDialog();
        }
    };

    return (
        <>
            {data.patient && <PatientCard patient={data.patient} />}
            {data.request && (
                <Grid container spacing="1rem" marginTop="0rem">
                    <Grid item xs={12} md={user.userType == 3 ? 6 : 12}>
                        <TabbedCard
                            request={data.request}
                            documents={data.documents}
                            medicalHistory={data.medicalHistory}
                            examination={data.examination}
                            reports={data.reports}
                            onDocumentsUpdate={loadData}
                        />
                    </Grid>
                    {user.userType === 3 && (
                        <Grid item xs={12} md={6}>
                            <QuestionnaireCard ref={questionnaireRef} request={data.request} />
                        </Grid>
                    )}
                </Grid>
            )}
            {user.userType === 3 && data.request && (
                <ReportCard
                    onSaveDraft={handleSaveDraft}
                    onSendReport={handleSendReport}
                    request={data.request}
                />
            )}
        </>
    );
};

const TabbedCard = ({
    request,
    documents,
    medicalHistory,
    examination,
    reports,
    onDocumentsUpdate,
}: {
    request: any;
    documents: any[];
    medicalHistory: any;
    examination: any[];
    reports: any[];
    onDocumentsUpdate: () => void;
}) => {
    const { user } = useUserProvider();
    const [tabValue, setTabValue] = useState(0);

    return (
        <>
            <Card
                sx={{
                    padding: "0rem",
                    borderRadius: "1rem",
                }}
            >
                <Tabs
                    value={tabValue}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Documenti" onClick={() => setTabValue(0)} />
                    <Tab label="Anamnesi" onClick={() => setTabValue(1)} />
                    <Tab label="Esame Obiettivo" onClick={() => setTabValue(2)} />
                    {user.userType === 3 && (
                        <Tab label="Referti" onClick={() => setTabValue(3)} />
                    )}
                </Tabs>
                <Divider />
                <Box display={tabValue === 0 ? "block" : "none"}>
                    <DocumentsTab documents={documents} request={request} onDocumentsUpdate={onDocumentsUpdate} />
                </Box>
                <Box display={tabValue === 1 ? "block" : "none"}>
                    <MedicalHistoryTab medicalHistory={medicalHistory} />
                </Box>
                <Box display={tabValue === 2 ? "block" : "none"}>
                    <ExaminationTab examination={examination} />
                </Box>
                {user.userType === 3 && (
                    <Box display={tabValue === 3 ? "block" : "none"}>
                        <ResultsTab reports={reports} />
                    </Box>
                )}
            </Card>
        </>
    );
};

const DocumentsTab = ({ documents, request, onDocumentsUpdate }: { documents: any[]; request: any, onDocumentsUpdate: () => void }) => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { createModalDialog } = useModalDialog();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleUnassignDocument = (documentId: number) => {
        createModalDialog(null, {
            title: "Rimuovi documento",
            content: (
                <>
                    <Typography variant="body1" color={theme.palette.text.secondary}>
                        Sei sicuro di voler rimuovere il documento?
                    </Typography>
                </>
            ),
            actions: [
                {
                    label: "Annulla",
                    onClick: () => {
                    },
                },
                {
                    label: "Rimuovi",
                    onClick: async () => {
                        openLoadingDialog();

                        try {
                            await unassignDocument({
                                id_documento: documentId,
                                id_richiesta: request.id_richiesta,
                            });
                            enqueueSnackbar("Documento rimosso con successo", { variant: "success" });

                            if (onDocumentsUpdate) {
                                onDocumentsUpdate();
                            }
                        } catch (error: any) {
                            enqueueSnackbar("Errore durante la rimozione del documento", { variant: "error" });
                        } finally {
                            closeLoadingDialog();
                        }
                    },
                },
            ],
        });
    };

    const handleDownloadDocument = async (documentId: number, documentName: string) => {
        openLoadingDialog();

        try {
            const response = await downloadDocument({ id_documento: documentId });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", documentName);
            link.click();
        } catch (error: any) {
            enqueueSnackbar("Errore durante il download del documento", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleViewDocument = async (documentId: number, documentName: string) => {
        openLoadingDialog();
        try {
            const response = await downloadDocument({ id_documento: documentId });
            const _file = new File([response.data], documentName, { type: response.headers["content-type"] });

            const isChromiumBased = () => {
                const userAgent = navigator.userAgent;
                const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
                const isOpera = /OPR/.test(userAgent);
                const isEdge = /Edg/.test(userAgent);

                return isChrome || isOpera || isEdge;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const result = (reader.result as string).split(",")[1];

                let url =
                    _file.type === "application/pdf" ?
                        'data:application/pdf;base64,' + result :
                        'data:' + _file.type + ';base64,' + result;

                if (isChromiumBased()) {
                    url += "#toolbar=0&navpanes=0&scrollbar=0&statusbar=0";
                }

                createModalDialog(null, {
                    title: "Visualizza documento",
                    content: (
                        <>
                            <iframe
                                src={url}
                                style={{ width: "100%", height: "80vh" }}
                                frameBorder="0"
                            />
                        </>
                    ),
                    actions: [
                        {
                            label: "Chiudi",
                            onClick: () => {
                            },
                        },
                    ],
                    maxWidth: "xl",
                });
            }

            reader.readAsDataURL(_file);
        } catch (error: any) {
            enqueueSnackbar("Errore durante il download del documento", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box>
            {documents.length === 0 && (
                <Box
                    padding="2rem"
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                    <MaterialIcon icon="file_copy" color={theme.palette.text.secondary} size="2rem" />
                    <Typography variant="body1" color="textSecondary" marginTop="1rem">
                        Nessun documento trovato
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MaterialIcon icon="upload" />}
                        sx={{ marginTop: "2rem" }}
                        onClick={handleDialogOpen}
                    >
                        Carica documenti
                    </Button>
                </Box>
            )}
            {documents.length > 0 && (
                <>
                    <List>
                        {documents.map((document, index) => (
                            <ListItem key={document.id_documento}>
                                <Box
                                    sx={{
                                        width: "3rem",
                                        height: "3rem",
                                        bgcolor: theme.palette.divider,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <MaterialIcon icon="description" color={theme.palette.text.primary} />
                                </Box>
                                <ListItemText
                                    primary={document.name}
                                    secondary={document.timestamp_creazione}
                                    sx={{
                                        marginLeft: "1rem",
                                        marginRight: "auto",
                                        maxWidth: "calc(90% - 6rem)",
                                        overflow: "hidden",
                                    }}
                                />
                                {user.userType === 3 && (
                                    <IconButton onClick={() => handleViewDocument(document.id_documento, document.name)}>
                                        <MaterialIcon icon="visibility" />
                                    </IconButton>
                                )}
                                <IconButton onClick={() => handleDownloadDocument(document.id_documento, document.name)}>
                                    <MaterialIcon icon="download" />
                                </IconButton>
                                <IconButton
                                    onClick={() => handleUnassignDocument(document.id_documento)}
                                    sx={{ marginRight: "1rem" }}
                                >
                                    <MaterialIcon icon="delete" color={theme.palette.error.main} />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box padding="2rem" sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MaterialIcon icon="upload" />}
                            onClick={handleDialogOpen}
                        >
                            Carica documenti
                        </Button>
                    </Box>
                </>
            )}
            <DialogUploadDocuments
                open={dialogOpen}
                onClose={handleDialogClose}
                onUpdate={onDocumentsUpdate}
                id_richiesta={request.id_richiesta}
                id_utente={request.id_paziente}
            />
        </Box>
    );
};

const MedicalHistoryTab = ({ medicalHistory }: { medicalHistory: any }) => {
    console.log("updated medical history", medicalHistory);

    if (!medicalHistory) {
        return <Box padding="2rem">Caricamento...</Box>;
    }

    const categorizedMedicalHistory = MEDICAL_HISTORY_KEYS.map((category) => {
        const keys = category.keys
            .map((key) => {
                if (
                    medicalHistory[key.key] &&
                    medicalHistory[key.key] !== "" &&
                    medicalHistory[key.key] !== null &&
                    medicalHistory[key.key] !== 0
                ) {
                    return {
                        key: key.key,
                        name: key.name,
                        value: medicalHistory[key.key],
                        ifYes: key.ifYes
                            ? {
                                key: key.ifYes.key,
                                name: key.ifYes.name,
                                value: medicalHistory[key.ifYes.key],
                            }
                            : null,
                    };
                }
                return null;
            })
            .filter((key) => key !== null);

        return {
            category: category.category,
            keys,
        };
    });

    const filteredMedicalHistory = categorizedMedicalHistory.filter(
        (category: any) => category.keys.filter((key: any) => key).length > 0
    );

    return (
        <Box>
            <List disablePadding>
                {filteredMedicalHistory &&
                    filteredMedicalHistory.map((category: any, index: number) => (
                        <>
                            <ListSubheader key={index}>{category.category}</ListSubheader>
                            {category.keys.map((key: any, keyIndex: number) => (
                                <ListItem key={keyIndex}>
                                    <ListItemText
                                        primary={key.name}
                                        secondary={key.value == 1 ? "Sì" : key.value == 0 ? "No" : key.value}
                                    />
                                    {key.ifYes && (
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={key.ifYes.name} secondary={key.ifYes.value} />
                                            </ListItem>
                                        </List>
                                    )}
                                </ListItem>
                            ))}
                            {index < filteredMedicalHistory.length - 1 && <Divider />}
                        </>
                    ))}
                {filteredMedicalHistory?.length === 0 && (
                    <ListItem>
                        <ListItemText primary="Nessuna anamnesi da mostrare" />
                    </ListItem>
                )}
            </List>
        </Box>
    );
};

const ExaminationTab = ({ examination }: { examination: any[] }) => {
    if (!examination.length) {
        return <Box padding="2rem">Caricamento...</Box>;
    }

    const filteredExamination = examination.filter(
        (item: any) => item.risposta !== null && item.risposta !== ""
    );

    return (
        <Box>
            <List disablePadding>
                {filteredExamination &&
                    filteredExamination.map((item: any, index: number) => {
                        let sotto_risposte = item.sotto_risposte || [];
                        sotto_risposte = sotto_risposte.filter((item: any) => {
                            return item !== null && item !== "";
                        });

                        return (
                            <>
                                <ListItem key={index}>
                                    <ListItemText primary={item.domanda} secondary={item.risposta} />
                                    {(sotto_risposte.length > 0) && (
                                        <List>
                                            {sotto_risposte.map((subItem: any, subIndex: number) => (
                                                <ListItem key={subIndex}>
                                                    <ListItemText primary={subItem.domanda} secondary={subItem.risposta} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    )}
                                </ListItem>
                            </>
                        )
                    })}
                {filteredExamination?.length === 0 && (
                    <ListItem>
                        <ListItemText primary="Nessun esame obiettivo trovato" />
                    </ListItem>
                )}
            </List>
        </Box>
    );
};

const ResultsTab = ({ reports }: { reports: any[] }) => {
    return (
        <Box>
            <List disablePadding>
                {reports.map((result, index) => (
                    <>
                        <ListItem key={index}>
                            <ListItemText primary={`Tipo Referto: ${result.nome_categoria}`} secondary={result.testo_refertazione} />
                        </ListItem>
                        {index < reports.length - 1 && <Divider />}
                    </>
                ))}
            </List>
        </Box>
    );
};

const ReportCard = ({
    request,
    onSendReport,
    onSaveDraft,
}: {
    request: any;
    onSendReport: (report: string, idoneo: boolean) => void;
    onSaveDraft: () => void;
}) => {
    const [reportText, setReportText] = useState<string>("");

    if (request.fl_completo_richiesta) {
        return <></>;
    }

    return (
        <Card
            sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginTop: "1rem",
            }}
        >
            <OutlinedInput
                label="Note aggiuntive"
                multiline
                fullWidth
                rows={2}
                placeholder="Inserisci le tue note aggiuntive"
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
            />
            <Box
                sx={{
                    display: "flex",
                    marginTop: "1rem",
                    gap: "1rem",
                }}
            >
                <Button variant="text" color="primary" onClick={onSaveDraft}>
                    Salva bozza
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    onClick={() => onSendReport(reportText, false)}
                    sx={{ marginLeft: "auto" }}
                >
                    Non idoneo
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSendReport(reportText, true)}
                >
                    Idoneo
                </Button>
            </Box>
        </Card>
    );
};

const PatientCard = ({ patient }: { patient: any }) => {
    return (
        <Card
            sx={{
                padding: "1rem",
                borderRadius: "1rem",
            }}
        >
            <Grid container spacing="1rem">
                <Grid item xs={12} md={6} sm={4}>
                    <ListItemText primary="Nome" secondary={patient.nome} />
                </Grid>
                <Grid item xs={12} md={6} sm={4}>
                    <ListItemText primary="Cognome" secondary={patient.cognome} />
                </Grid>
                <Grid item xs={12} md={6} sm={4}>
                    <ListItemText primary="Codice Fiscale" secondary={patient.codice_fiscale} />
                </Grid>
                <Grid item xs={12} md={6} sm={4}>
                    <ListItemText primary="Data di Nascita" secondary={patient.data_nascita} />
                </Grid>
                <Grid item xs={12} md={6} sm={4}>
                    <ListItemText primary="Email" secondary={patient.email} />
                </Grid>
                <Grid item xs={12} md={6} sm={4}>
                    <ListItemText primary="Telefono" secondary={patient.telefono} />
                </Grid>
            </Grid>
        </Card>
    );
};

const QuestionnaireCard = forwardRef(({ request }: { request: any }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const theme = useTheme();

    const [questionnaire, setQuestionnaire] = useState<any[]>([]);
    const [answers, setAnswers] = useState<{ [key: number]: string }>({});
    const [otherAnswers, setOtherAnswers] = useState<{ [key: number]: string }>({});

    useEffect(() => {
        loadQuestionnaire();
    }, []);

    const loadQuestionnaire = async () => {
        if (!request.id_richiesta || request.fl_completo_richiesta) {
            return;
        }

        openLoadingDialog();
        try {
            const response = await getQuestionnaire({ id_richiesta: request.id_richiesta });
            setQuestionnaire(response.data);
            // Popolare lo stato delle risposte con le risposte salvate
            const initialAnswers: { [key: number]: string } = {};
            const initialOtherAnswers: { [key: number]: string } = {};
            response.data.forEach((question: any) => {
                initialAnswers[question.id_domanda] = question.risposta || '';
                if (question.risposta && question.risposta !== 'Sì' && question.risposta !== 'No' && question.risposta !== 'Altro') {
                    initialAnswers[question.id_domanda] = 'Altro';
                    initialOtherAnswers[question.id_domanda] = question.risposta;
                }
            });
            setAnswers(initialAnswers);
            setOtherAnswers(initialOtherAnswers);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento del questionario", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    };

    useImperativeHandle(ref, () => ({
        getAnswers: () => {
            const finalAnswers = { ...answers };
            Object.keys(otherAnswers).forEach((key: any) => {
                if (otherAnswers[key] !== '') {
                    finalAnswers[parseInt(key)] = otherAnswers[key];
                }
            });
            return finalAnswers;
        }
    }));

    const handleAnswerChange = (id_domanda: number, value: string) => {
        setAnswers(prev => ({ ...prev, [id_domanda]: value }));
        if (value !== 'Altro') {
            setOtherAnswers(prev => ({ ...prev, [id_domanda]: '' }));
        }
    };

    const handleOtherChange = (id_domanda: number, event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherAnswers(prev => ({ ...prev, [id_domanda]: event.target.value }));
    };

    return (
        <Card
            sx={{
                padding: '2rem',
                borderRadius: '1rem',
            }}
        >
            {questionnaire.length === 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="body1" color="textSecondary" marginTop="1rem">Nessun questionario trovato</Typography>
                </Box>
            )}
            {questionnaire.length > 0 && (
                questionnaire.map((question, index) => (
                    <Box key={index} marginBottom={index === questionnaire.length - 1 ? "0" : '1rem'}>
                        <Typography variant="subtitle1" marginBottom=".5rem">{question.testo_domanda}</Typography>
                        <RadioGroup
                            value={answers[question.id_domanda] || ''}
                            onChange={(e) => handleAnswerChange(question.id_domanda, e.target.value)}>
                            <FormControlLabel value="Sì" control={<Radio />} label="Sì" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Altro" control={<Radio />} label="Altro" />
                        </RadioGroup>
                        {answers[question.id_domanda] === 'Altro' && (
                            <OutlinedInput
                                placeholder="Inserisci la risposta"
                                value={otherAnswers[question.id_domanda] || ''}
                                onChange={(e: any) => handleOtherChange(question.id_domanda, e)}
                                fullWidth
                                margin="dense"
                                sx={{ marginTop: '1rem' }}
                            />
                        )}
                    </Box>
                ))
            )}
        </Card>
    );
});

export default RequestDetails;
